<template>
    <div class="error-container" :style="{ width: width }">
        <div class="dashboard-error-img" :style="{ height: imgHeight }" />
        <div class="error-message">
            {{ errorMessage }}， 請<span
                class="reload-link"
                @click="reacquireData"
                >按此重新取得</span
            >。
        </div>
    </div>
</template>
<script>
export default {
    name: 'DashboardErrorMessage',
    emits: ['reacquire'],
    props: {
        width: {
            type: String,
            default: '100%'
        },
        imgHeight: {
            type: String,
            default: '80px'
        },
        errorMessage: {
            type: String,
            default: '無法取得資料'
        },
        functionName: {
            type: String,
            default: ''
        }
    },
    methods: {
        reacquireData: function () {
            this.$setGaEvent(`${this.functionName}`, 'error-reacquire')
            this.$emit('reacquire')
        }
    }
}
</script>

<style lang="scss" scoped>
.error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .dashboard-error-img {
        width: 100%;
        height: 80px;
        margin-bottom: 10px;
    }
    .error-message {
        width: 100%;
        color: $ninth-grey;
        font-size: 14px;
        text-align: center;
        word-break: keep-all;
        .reload-link {
            cursor: pointer;
            color: $fourth-blue;
            text-decoration: underline;
        }
    }
}
</style>
